<template>
    <div class="card-body">
        <p class="card-title-desc"></p>
        <b-form @submit.prevent="updatePassword" class="form-horizontal mt-4">
            <b-form-group
                    id="input-group-1"
                    label-for="old_password"
                    invalid-feedback="Password is required"
            >
                <b-form-input
                        id="old_password"
                        v-model="old_password"
                        type="password"
                        placeholder="Enter Old Password"
                        :class="{ 'is-invalid': isValid && $v.old_password.$error }"
                ></b-form-input>
            </b-form-group>

            <b-form-group
                    id="input-group-2"
                    label-for="new_password"
            >
                <b-form-input
                        id="new_password"
                        v-model="new_password"
                        type="password"
                        placeholder="Enter New Password"
                        :class="{ 'is-invalid': isValid && $v.new_password.$error }"
                ></b-form-input>
                <label class="invalid-feedback" v-if="!$v.new_password.required">New password is required</label>
                <label class="invalid-feedback" v-if="!$v.new_password.minLength">Password must have at least {{
                    $v.new_password.$params.minLength.min }} letters.</label>
            </b-form-group>

            <b-form-group
                    id="input-group-3"
                    label-for="confirm_password"
            >
                <b-form-input
                        id="confirm_password"
                        v-model="confirm_password"
                        type="password"
                        placeholder="Enter Confirm Password"
                        :class="{ 'is-invalid': isValid && $v.confirm_password.$error }"
                ></b-form-input>
                <label class="invalid-feedback" v-if="!$v.confirm_password.required">Confirm password is
                    required</label>
                <label class="invalid-feedback" v-if="!$v.confirm_password.sameAsPassword">Passwords must be
                    identical.</label>
            </b-form-group>

            <div class="form-group row">
                <div class="col-sm-12">
                    <b-button type="submit" variant="primary" class="w-md">Save</b-button>
                </div>
            </div>
        </b-form>
    </div>
</template>

<script>
    import {authMethods} from "@/state/helpers";
    import {required, sameAs, minLength} from "vuelidate/lib/validators";

    export default {
        data() {
            return {
                old_password: "",
                new_password: "",
                confirm_password: "",
                isValid: false,
            };
        },
        validations: {
            old_password: {
                required
            },
            new_password: {
                required,
                minLength: minLength(8)
            },
            confirm_password: {
                required,
                sameAsPassword: sameAs('new_password')
            }
        },
        computed: {},
        mounted() {
        },
        created() {
        },

        methods: {
            ...authMethods,
            updatePassword() {
                this.isValid = true;
                    this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.updatedPassword({old_password: this.old_password, new_password: this.new_password}).then(res => {
                    if (res.status === 200) {
                        this.$swal({
                            icon: 'success',
                            toast: true,
                            text: 'Password Updated Successfully',
                            showCloseButton: false,
                            showConfirmButton: false,
                            timer: 3000
                        })
                    }
                }).catch(error => {
                    this.$swal({
                        icon: 'error',
                        toast: true,
                        text: error.data.message,
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    this.unauthorizedError(error)
                })
            },
            unauthorizedError(err) {
                if (err.data.status === 401) {
                    return this.$router.push('/logout');
                }
            }
        }
    };
</script>
