
<template>
          <div class="card-body">
            <p class="card-title-desc"></p>
                <b-form @submit.prevent="updateProfile" class="form-horizontal mt-4">
                  <b-form-group
                    id="input-group-2"
                    label="Image"
                    label-for="input-2"
                  >
                  <b-form-file
                      id="input-2"
                      v-model="file"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      class="form-control"
                      :class="{ 'is-invalid': isValid && $v.file.$error }"
                  ></b-form-file>
                  <label class="invalid-feedback" v-if="!$v.file.required">Select profile image</label>                  
                  </b-form-group>

                  <b-form-group id="input-group-1" label="Name" label-for="input-1">
                    <b-form-input
                      id="input-1"
                      v-model="name"
                      placeholder="Enter Name"
                      :class="{ 'is-invalid': isValid && $v.name.$error }"
                    ></b-form-input>
                  <label class="invalid-feedback" v-if="!$v.name.required">Name is required</label>                  
                  </b-form-group>

                  <div class="form-group row">
                    <div class="col-sm-12">
                      <b-button type="submit" variant="primary" class="w-md">Save</b-button>
                    </div>
                  </div>
                </b-form>
            </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { authMethods } from "@/state/helpers";

export default {
  data() {
    return {
        file : null,
        name : "",
        isValid: false
    };
  },
  validations: {
    name: {
      required
    },
    file: {
      required
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
  },

  methods: {
    ...authMethods,
    updateProfile(){
      this.isValid = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
        this.updatedProfile({file:this.file,name:this.name}).then(res => {
        if (res.status == 200) {
            this.$swal({
            icon : 'success',
            toast : true,
            text: 'Profile Updated Successfully',
          showCloseButton: false,
              showConfirmButton: false,
              timer: 3000
        })
        }
      }).catch(error => {
          this.$swal({
            icon : 'error',
            toast : true,
            text: error.data.message,
          showCloseButton: false,
            showConfirmButton: false,
            timer: 3000
        });
          this.unauthorizedError(error)
      })
    },
    unauthorizedError(err) {
      if (err.data.status === 401) {
        return this.$router.push('/logout');
      }
    }
  }
};
</script>
