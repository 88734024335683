
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-head">
              <button
              class="btn btn-secondary"
                  v-for="tab in tabs"
                  v-bind:key="tab"
                  v-bind:class="{ active: currentTab === tab }"
                  v-on:click="currentTab = tab"
              >{{ tab }}</button>
          </div>
            <component v-bind:is="currentTabComponent"></component>
          </div>
        </div>
      </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Profile from "./profile";
import Password from "./managePassword";
// import axios from '../../../axios';
/**
 * Advanced table component
 */
export default {
  page: {
    title: "User Profile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "User Profile",
      items: [
        {
          text: "Any",
          href: "/"
        },
        {
          text: "User Profile",
          href: "/"
        },
      ],
      currentTab: 'Profile',
      tabs: ['Profile', 'Password'],
    };
  },
  computed: {
        currentTabComponent: function () {
          return this.currentTab == 'Profile' ? Profile : Password ;
    }
  },
  mounted() {
  },
  created() {
  },

  methods: {
  }
};
</script>
